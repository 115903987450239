import React from 'react'
import Carousel from '../HelloRetail/Carousel'
import { useQuery } from 'react-apollo'
import manualProductsRecommendationQuery from './ManualProductsRecommendationQuery.gql'

function prepareArticleNumbersToFetch(products) {
   if (!products) return []

   const extractedArticleNumbersArr = products.reduce(
      (accumulatedArticleNumbers, item) => {
         const artNr = item?.properties[0]?.value?.articleNumber
         if (artNr !== null && artNr !== undefined) {
            accumulatedArticleNumbers.push(artNr)
         }
         return accumulatedArticleNumbers
      },
      []
   )

   return extractedArticleNumbersArr

}

export const ManualRecommendation = ({ properties, products }) => {
   const carouselTitle = properties.find(field => field.name == 'title')?.value
      ?.string


   const articleNumbersToFetch = prepareArticleNumbersToFetch(products)

   // Issue: When any of the article numbers is not found on the server,
   // the request fails with a 'ProductNotFound' error.
   const { data, error, loading } = useQuery(
      manualProductsRecommendationQuery,
      {
         variables: {
            articles: [...articleNumbersToFetch],
         },
         // Skip query if articleNumbersToFetch is falsy/empty-array
         skip: !articleNumbersToFetch?.length || !articleNumbersToFetch,
      }
   )

   // if (error) {
   //    return <></>
   // }

   return (
      <div className="retail-wrapper">
         <Carousel
            className="py-32"
            products={data?.products}
            loading={loading}
            title={carouselTitle}
            properties={properties}
         ></Carousel>
      </div>
   )
}
