import React from 'react'
import * as elementTypes from './elementTypes'

import { Accordion } from './Accordion'
import { CtaCards } from './CtaCards'
import { EmbedYoutube } from './EmbedYoutube'
import { HeroSection } from './HeroSection'
import { ImageCardOverlay } from './ImageCardOverlay'
import { ImageColumn } from './ImageColumn'
import { LinkCardsCarousel } from './LinkCardsCarousel'
import { Spacing } from './Spacing'
import { Text } from './Text'
import { TextColumn } from './TextColumn'
import { FAQAccordion } from "./FAQAccordion"
import { HelloRetailRecommendation } from "./HelloRetailRecommendation"
import { ManualRecommendation } from "./ManualRecommendation"
import { OneColumn } from './VBElements/OneColumn'
import { TwoColumn } from './VBElements/TwoColumn'
import { ThreeColumn } from './VBElements/ThreeColumn'
import { FourColumn } from './VBElements/FourColumn'
import { Blurb } from './VBElements/Blurb'
import { Steps } from './VBElements/Steps'
import VideoBanner from '../Core/Banners/VideoBanner'
import PictureBanner from '../Core/Banners/PictureBanner'
import { BrandList } from './BrandList'
import { Kundo } from './VBElements/Kundo'
import { ContentWidget } from './VBElements/ContentWidget'
import { BubbleWidget } from './VBElements/BubbleWidget'
import { ArticlesViewerManual } from './VBElements/ArticlesViewerManual'

function getFields(item) {
   let output = {}

   item?.properties?.forEach(field => {
      // Look for value
      let value = undefined
      if (field?.value?.string) {
         value = field.value.string
      }
      if (field?.value?.bool) {
         value = field.value.bool
      }

      if (value !== undefined) {
         output[field.name] = value
      }
   })

   return output
}

// Map to new object to make writing components more smood
function mapItem(item) {
   let output = {
      type: item.type,
      fields: {},
      children: [],
   }

   output.properties = getFields(item)

   if (item.children) {
      output.properties.children = item.children.map(child => getFields(child))
   }

   return output
}

/**
 * @typedef {object} Props
 * @property {array} items - custom element items
 * @property {object} category - current category, used by some custom components
 *
 * Renders all custom elements given as 'items'
 * @param {Props} props
 */
export const RenderCustomElements = ({ items }) => {
   if (!items) return null
   return items.map((item, key) => {

      if(elementTypes.MANUAL_RECOMMENDATION != item.type){
         item = mapItem(item)
      }

      switch (item.type) {
         case elementTypes.ACCORDION:
            return <Accordion key={key} {...item.properties} />

         case elementTypes.HERO_SECTION:
            return <HeroSection key={key} {...item.properties} />

         case elementTypes.SPACING:
            return <Spacing key={key} {...item.properties} />

         case elementTypes.CTA_CARDS:
            return <CtaCards key={key} {...item.properties} />

         case elementTypes.LINK_CARDS_CAROUSEL:
            return <LinkCardsCarousel key={key} {...item.properties} />

         case elementTypes.IMAGE_COLUMN:
            return <ImageColumn key={key} {...item.properties} />

         case elementTypes.TEXT_COLUMN:
            return <TextColumn key={key} {...item.properties} />

         // case elementTypes.IMAGE_CARD_OVERLAY:
         //    return <ImageCardOverlay key={key} {...item.properties} />

         case elementTypes.EMBED_YOUTUBE:
            return <EmbedYoutube key={key} {...item.properties} />

         case elementTypes.TEXT:
            return <Text key={key} {...item.properties} />

         case elementTypes.FAQ_ACCORDION:
            return <FAQAccordion key={key} {...item.properties}></FAQAccordion>

         case elementTypes.HELLO_RETAIL_RECOMMENDATION:
            return <HelloRetailRecommendation key={key} {...item.properties}></HelloRetailRecommendation>

         case elementTypes.MANUAL_RECOMMENDATION:
            return <ManualRecommendation key={key} properties={item.properties} products={item.children}></ManualRecommendation>

         case elementTypes.VB_ONE_COLUMN:
            return <OneColumn key={key} properties={item.properties}></OneColumn>

         case elementTypes.VB_TWO_COLUMN:
            return <TwoColumn key={key} properties={item.properties}></TwoColumn>

         case elementTypes.VB_THREE_COLUMN:
            return <ThreeColumn key={key} properties={item.properties}></ThreeColumn>

         case elementTypes.VB_FOUR_COLUMN:
            return <FourColumn key={key} properties={item.properties}></FourColumn>
            
         case elementTypes.VB_BLURB:
            return <Blurb key={key} properties={item.properties}></Blurb>     

         case elementTypes.VB_STEPS:
            return <Steps key={key} properties={item.properties}></Steps>

         case elementTypes.VB_FAQ_ACCORDION:
            return <FAQAccordion key={key} {...item.properties}></FAQAccordion>

         case elementTypes.VB_VIDEO_BANNER:
            return <VideoBanner key={key} properties={item.properties}></VideoBanner>

         case elementTypes.VB_BRAND_LIST:
            return <BrandList key={key} properties={item.properties}></BrandList>

         case elementTypes.VB_KUNDO:
            return <Kundo key={key} properties={item.properties}></Kundo>

         case elementTypes.VB_CONTENT_WIDGET:
            return <ContentWidget key={key} properties={item.properties}></ContentWidget>

         case elementTypes.VB_BUBBLE_WIDGET:
            return <BubbleWidget key={key} properties={item.properties}></BubbleWidget>

         case elementTypes.VB_CATEGORY_SETTINGS:
            return <React.Fragment key={key}></React.Fragment>

         case elementTypes.VB_PICTURE_BANNER:
            return <PictureBanner key={key} properties={item.properties}></PictureBanner>
            
         case elementTypes.VB_MANUAL_BLOGPOSTS:
            return <ArticlesViewerManual key={key} properties={item.properties}></ArticlesViewerManual>

         default:
            console.log(`No custom element for ${item.type}`)
            return null
      }
   })
}

export default RenderCustomElements
